import React from 'react'
import styled from 'styled-components'

import { Layout } from '../../components/layout'
import { Footer } from '../../components/footer'
import { Teaser } from '../../components/teaser'

import { Image } from '../../components/common/image'
import { Grid, Row, Col } from '../../components/common/grid'
import { Space } from '../../components/common/space'
import { H4 } from '../../components/common/heading'

const List = styled.ul`
  margin: 0;
`

const ListItem = styled.li`
  color: #69707b;
  text-align: justify;
  margin-bottom: 10px;
`

const title = 'Chimpanzee Energieriegel'
const description =
  'Eine Mischung aus Kohlenhydraten und reich an Ballaststoffen und Eiweiß'
const keywords = ''

const EnergieRiegel = () => (
  <Layout title={title} description={description} keywords={keywords}>
    <Teaser title={title} description={description} imageName="energieriegel" />

    <Grid>
      <Row centered>
        <Col xs={12} md={9}>
          <div>
            <Space bottom={20}>
              <H4>Chimpanzee Energieriegel (55g)</H4>
            </Space>
            <p>
              Die Chimpanzee Energieriegel sind eine wunderbare, natürliche
              Energiequelle für jede Aktivität. Sie enthalten eine Mischung aus
              komplexen Kohlenhydraten, sind reich an Ballaststoffen und Eiweiß
              und bilden daher einen großartigen Energielieferanten.
            </p>

            <Space top={50} bottom={20}>
              <H4>Geschmacksrichtungen</H4>
            </Space>

            <List>
              <ListItem>Apfel & Ingwer (Vegan)</ListItem>
              <ListItem>Datteln & Schokolade (Vegan)</ListItem>
              <ListItem>Zitrone (Vegan)</ListItem>
              <ListItem>Rosine & Walnuss (Vegan)</ListItem>
              <ListItem>Schokolade & Kaffee (Vegetarisch)</ListItem>
              <ListItem>Erdnuss (Vegan)</ListItem>
              <ListItem>Rote Beete & Karroten (Vegan)</ListItem>
              <ListItem>Cashew & Karamell (Vegetarisch)</ListItem>
              <ListItem>Marille (Vegan)</ListItem>
            </List>
          </div>
        </Col>
      </Row>

      <Space top={50} bottom={20}>
        <Row centered>
          <Col xs={12} md={9}>
            <H4>Produktbeispiele</H4>
          </Col>
        </Row>
      </Space>

      <Row centered>
        <Col xs={12} sm={4} md={3} bottom={50}>
          <Image name="energieriegel_apricot" />
        </Col>
        <Col xs={12} sm={4} md={3} bottom={50}>
          <Image name="energieriegel_lemon" />
        </Col>
        <Col xs={12} sm={4} md={3} bottom={50}>
          <Image name="energieriegel_raisin" />
        </Col>
      </Row>
    </Grid>

    <Footer bottom={50} />
  </Layout>
)

export default EnergieRiegel
